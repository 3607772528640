import React, { Fragment } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ProjectHeader from '../components/ProjectHeader'
import ProjectDescription from '../components/ProjectDescription'
import ProjectGrid from '../components/ProjectGrid'
import ProjectNextLink from '../components/ProjectNextLink'
import InstagramFeed from '../components/InstagramFeed'
import ContactBoxes from '../components/ContactBoxes'
import Footer from '../components/Footer'

import headerRightBanner from '../images/projects/cozinha-rj/header-right-banner.jpg'
import projecPhoto1 from '../images/projects/cozinha-rj/apt-template-1.jpg'
import projecPhoto2 from '../images/projects/cozinha-rj/apt-template-2.jpg'
import projecPhoto3 from '../images/projects/cozinha-rj/apt-template-3.jpg'
import projecPhoto4 from '../images/projects/cozinha-rj/apt-template-4.jpg'
import projecPhoto5 from '../images/projects/cozinha-rj/apt-template-5.jpg'
import projecPhoto6 from '../images/projects/cozinha-rj/apt-template-6.jpg'
import projecPhoto7 from '../images/projects/cozinha-rj/apt-template-7.jpg'

import '../styles/global.scss'

const dynamicInfos = {
  headerImage: headerRightBanner,
  title: 'Cozinha',
  subTitle: 'RJ',
  typeService: 'PROJETO',
  duration: '06 meses',
  deliverDate: '2016',
  local: 'Rio de Janeiro',
  localShortened: 'RJ',
  color: '#fe8983',
  description: (
    <Fragment>
      <p>
        Esse projeto foi nosso primeiro <b>projeto a distância</b>, depois deste
        muitos outros vieram, mas o carinho pelo primeiro é sempre especial. É a
        cozinha de uma casa antiga no Rio de Janeiro.
      </p>
      <p>
        Um espaço com dimensões super confortáveis, mas que tinha um péssimo
        aproveitamento do espaço. Resolvemos o problema criando uma ilha que
        separou espaço de cozinhar do espaço de copa. Ainda utilizamos o espaço
        sob a escada para criar uma <b>adega climatizada</b> com toda a coleção
        de vinhos do cliente.
      </p>
    </Fragment>
  ),
  photosFirstRow: [
    { id: 'gap1', gap: true, className: 'dimensionGap' },
    { id: '01', image: projecPhoto1, className: 'dimensionOption1' },
    { id: '02', image: projecPhoto4, className: 'dimensionOption2' },
    { id: '03', image: projecPhoto3, className: 'dimensionOption3' },
    { id: '04', image: projecPhoto2, className: 'dimensionOption4' }
  ],
  photosSecondRow: [
    { id: '01', image: projecPhoto5, className: 'dimensionOption5' },
    { id: '02', image: projecPhoto6, className: 'dimensionOption5' },
    { id: '03', image: projecPhoto7, className: 'dimensionOption6' },
    { id: 'gap1', gap: true, className: 'dimensionGap' }
  ],
  nextProject: 'espaco-gourmet'
}

const IndexPage = () => (
  <Layout>
    <SEO title="Cozinha RJ" keywords={[`gatsby`, `application`, `react`]} />
    <ProjectHeader
      headerImage={dynamicInfos.headerImage}
      title={dynamicInfos.title}
      subTitle={dynamicInfos.subTitle}
    />
    <ProjectDescription
      typeService={dynamicInfos.typeService}
      duration={dynamicInfos.duration}
      deliverDate={dynamicInfos.deliverDate}
      local={dynamicInfos.local}
      localShortened={dynamicInfos.localShortened}
      description={dynamicInfos.description}
      color={dynamicInfos.color}
    />
    <ProjectGrid
      photosFirstRow={dynamicInfos.photosFirstRow}
      photosSecondRow={dynamicInfos.photosSecondRow}
    />
    <ProjectNextLink nextProject={dynamicInfos.nextProject} />
    <InstagramFeed />
    <ContactBoxes />
    <Footer />
  </Layout>
)

export default IndexPage
